import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Layout from "../components/layout";
import { SafeHtmlParser } from "../components/SafeHtmlParser";

const AboutPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				wpComponent(slug: { eq: "thank-you-page" }) {
					thankYouPageFields {
						heading
						body
						link {
							title
							url
						}
					}
				}
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	return (
		<Layout>
			<GatsbySeo title={title} language="en" noindex />
			<section>
				<Container>
					<Row className="py-5">
						<Col>
							<h1 className="pb-3">
								{data.wpComponent.thankYouPageFields.heading}
							</h1>
							<div className="d-block mb-3">
								<SafeHtmlParser
									htmlContent={data.wpComponent.thankYouPageFields.body}
								/>
							</div>
							<Link to={data.wpComponent.thankYouPageFields.link.url}>
								{data.wpComponent.thankYouPageFields.link.title}
							</Link>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default AboutPage;
